/**
 *  Archivo de Environment que contiene la config del sistema para un ambiente determinado
 *  En este caso para el ambiente de desarrollo
 *
  production: indica si la config es de un ambiente de produccion
  region: la region de aws

  identityPoolId: el id del pool de cognito
  userPoolId: el id del user pool de cognito
  clientId: el client id de cognito

  rekognitionBucket: bucket de rekognition
  albumName: album para rekognition
  bucketRegion: region del bucket de rekognition

  ddbTableName: tabla del dynamo db para guardar logs

  cognito_idp_endpoint:
  sts_endpoint:
  cognito_identity_endpoint:
  dynamodb_endpoint:
  s3_endpoint:
 */


//DESARROLLO



export const environment = {
  production: false,
  region: 'us-east-1',

  identityPoolId: 'us-east-1:ec9dac29-d1c9-40d1-b33a-8ae8c357df3d',
  userPoolId: 'us-east-1_aTut4fkhA',
  clientId: '4e61dn5qp1h05fv2gju5349cur',
  rekognitionBucket: 'rekognition-pics',
  albumName: "usercontent",
  bucketRegion: 'us-east-1',
  ddbTableName: 'LoginTrail',
  linkEVEx: 'https://sandbox.evex.cr/',
  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  dynamodb_endpoint: '',
  s3_endpoint: '',
  s3_endpointCedulas: 'https://dev-evex-cedulas.s3.amazonaws.com/',
  s3_endpointProfile: 'https://dev-evex-profileimg.s3.amazonaws.com/',
  api_url: 'https://apfbb9t4a8.execute-api.us-east-1.amazonaws.com/Prod', // 'https://ejh5oxl2bl.execute-api.us-east-1.amazonaws.com/dev' //url del api gateway
};
