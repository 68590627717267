import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { UserLoginService } from "../service/user-login.service";
import { CognitoUtil } from "../service/cognito.service";
import {Observable} from "rxjs/internal/Observable";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public userService: UserLoginService, public cognito: CognitoUtil) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let sub = this.cognito.getIdToken().subscribe((tkn) => {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${tkn}`
                    }
                });
        }, (err) => {
            console.log(err);
        },
            () => {
                sub.unsubscribe();
            }
        );
        return next.handle(request);
    }
}
