import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
// Import containers
import {FullLayoutComponent, SimpleLayoutComponent} from './containers';
// Import components
import {
  APP_SIDEBAR_NAV,
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
} from './components';
// Import directives
import {AsideToggleDirective, NAV_DROPDOWN_DIRECTIVES, ReplaceDirective, SIDEBAR_TOGGLE_DIRECTIVES} from './directives';
// Import routing module
import {AppRoutingModule} from './app.routing';
// Import 3rd party components
import {CognitoUtil, DynamoDBService, UserLoginService} from './service';
import {UserProfile} from './model';
import {FormsModule} from "@angular/forms";
import {ErrorHandlerService} from './service/error-handler.service';
import {AuthLayoutComponent} from './containers/auth-layout/auth-layout.component';
import {ProfileService} from "./service/profile.service";
import {ToasterModule, ToasterService} from "angular2-toaster";
import {TokenInterceptor} from "./service/token.interceptor";









const APP_CONTAINERS = [
  FullLayoutComponent,
  SimpleLayoutComponent,
  AuthLayoutComponent
];

const APP_COMPONENTS = [
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV
];

const APP_DIRECTIVES = [
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ToasterModule,

  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES,




  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    UserProfile, UserLoginService, CognitoUtil, DynamoDBService, ErrorHandlerService, ProfileService, ToasterService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
