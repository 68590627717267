import { Injectable } from '@angular/core';
import { UserProfile } from '../model';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { environment } from "../../environments/environment";
import {Subject} from "rxjs/internal/Subject";

@Injectable()
export class ProfileService {

    public profile: Subject<UserProfile> = new Subject<UserProfile>();
    private api_url = environment.api_url;
    private headers: HttpHeaders = new HttpHeaders();

    constructor(private http: HttpClient) {
        this.headers.append("Content-Type", "application/json");
    }
    //----------------------------------------------------------


    updatePerfilSimpleCuenta(name: string, email: string) {
        let params = {};
        params["nombreCompleto"] = name;
        params["correoElectronico"] = email;
        return this.http.put(this.api_url + "/perfil/cuenta", params, { headers: this.headers })
            .pipe(catchError(this.handleError)
            );
    }

    updatePerfil(urlNotificacion: string, urlComprobante: string): any {
        return this.http.put(this.api_url + "/perfil/cuenta", {"urlEventoComprobanteEntrante": urlComprobante}, { headers: this.headers })
            .pipe(catchError(this.handleError)
            );
    }

    getProfile(): any {
        var options = new HttpParams();
        return this.http.get(this.api_url + "/perfil/cuenta", { headers: this.headers, params: options })
            .pipe(
                catchError(this.handleError)
            );
    }
    getBusinessProfile(): any {
        var options = new HttpParams();
        return this.http.get(this.api_url + "/perfil/publico", { headers: this.headers, params: options })
            .pipe(
                catchError(this.handleError)
            );
    }




    private handleError(error) {
        console.log(error);
        return error;
    };

}
