import { Injectable } from '@angular/core';

@Injectable()
export class ErrorHandlerService {

    constructor() { }
    /**
     * Maneja los errores a nivel del sistema
     * @param error el error a manejar
     */
    public handleError(error:any){
        //actualmente solo lo imprime
        //TODO lanzar un mensaje con el error
        console.log("Error",error);

    }
}