import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserLoginService } from '../../../service/user-login.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html'
})
export class AppHeaderComponent {

  constructor(public router: Router, public userService: UserLoginService) {

  }
  /**
  * Cierra la sesión del usuario
  */
  logout() {
    this.userService.isAuthenticated().subscribe((response) => {
      if (response.valid) {
        this.userService.logout();
      }
      this.router.navigate(['/auth/login']);
    }, (error) => {
      this.router.navigate(['/factura/landing/']);
    });
  }
}
