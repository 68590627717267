import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserProfile } from './model/user-profile';
import { CognitoUtil } from './service/cognito.service';
import { UserLoginService } from './service/user-login.service';
import { UserRegistrationService } from './service/user-registration.service';
import { ProfileService } from './service/profile.service';


@Component({
  selector: 'body',
  template: ' <router-outlet></router-outlet>',
  providers: [UserRegistrationService, ProfileService]
})
export class AppComponent implements OnInit {

  constructor(private router: Router, public profile: UserProfile,
    public userService: UserLoginService, public cognito: CognitoUtil,
    private profileService: ProfileService) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    let sub = this.userService.isAuthenticated().subscribe((resp) => {
      if (!resp.valid) {
        this.router.navigate(["/auth/login"]);
      } else {
        this.profileService.getProfile();
      }
    },
      (err) => {
        console.log(err);
      },
      () => { sub.unsubscribe(); }
    );
    console.log("in UseractivityComponent");
  }
}

